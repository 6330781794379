<template>
  <tr>
    <td>{{ item.name }}</td>
    <td class="text-center">
      <div v-if="item.video_members">
        <img
          class="video-icon"
          :id="`video-member`"
          :src="'/img/video_icon.png'"
          :alt="`video-member`"
          :title="'video-member'"
        />
        <b-tooltip :target="`video-member`" triggers="hover">
          {{ $t("video_interview") }}
        </b-tooltip>
      </div>
      <game-image
        v-for="game in item.games"
        :key="`table-${item.id}-${game.id}`"
        :imageFileName="setGameIcon(game.identifier)"
        :gameName="game.name"
        :identifier="game.identifier"
        :detailed="false"
      />
    </td>
    <td>{{ item.finished_gameplays }} ({{ proportion }}%)</td>
    <td>{{ item.start_time | moment }}</td>
    <td>
      <button
        class="btn button-secondary"
        data-toggle="modal"
        data-target="#addModal"
        @click="openAssessmentDetailsModal"
      >
        {{ $t("dashboard.recruitment_list_tab.simple.show_more") }}
      </button>
    </td>
    <td>
      <button
        v-if="invite_only"
        class="btn button-secondary"
        @click="openInviteModal"
      >
        {{ $t("invitation.invite_candidates") }}
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import GameImage from "./GameImage.vue";
import { getGameIcon } from "../common/constants";
import filters from "../common/filters";

export default {
  mixins: [filters],
  components: {
    GameImage,
  },
  props: {
    item: {
      required: true,
    },
    router_link_params: {
      required: true,
    },
  },
  computed: {
    proportion() {
      const all = this.item.total;
      const finished = this.item.finished_gameplays;
      return isNaN(finished / all)
        ? 0
        : Math.round((finished / all) * 100 * 10) / 10;
    },
    invite_only() {
      return this.item.attributes.includes("INVITE_ONLY");
    },
  },
  methods: {
    setGameIcon(identifier) {
      return `${getGameIcon(identifier)}_icon.png`;
    },

    selectItem() {
      this.setSelectedAssessment(this.item);
    },

    openAssessmentDetailsModal() {
      this.selectItem();
      this.$emit("open-assessment-details-modal", true);
    },

    openInviteModal() {
      this.selectItem();
      this.$emit("open-invitation-modal", this.item);
    },

    ...mapActions("select", ["setSelectedAssessment"]),
  },
};
</script>
<style>
.icon {
  text-align: center !important;
}
.video-icon {
  height: 30px;
  margin: 4px;
}
</style>
