import { axios_no_auth } from './common/axios-interceptor';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import Vuelidate from 'vuelidate';
import VueMatomo from 'vue-matomo';
import VTooltip from 'v-tooltip';
import Vue2TouchEvents from 'vue2-touch-events';
import dev_settings from './common/dev-settings.json';
import { i18n } from './i18n';
import MissingSettingsError from './MissingSettingsError.vue';
import App from './App.vue';
import store from './common/store.js';
import { router } from './common/router';

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Datetime);
Vue.use(Vuelidate);
Vue.use(VTooltip, {
  defaultBoundariesElement: document.body,
});
Vue.use(Vue2TouchEvents);

if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_FORCE_SETTINGS_JSON === 'true') {
  // load /settings.json file
  axios_no_auth.get(`/settings.json?timestamp=${new Date().getTime()}`)
    .then(({ data }) => {
      // validate response
      if (!data) throw new Error('Missing settings.json.');
      if (!data.api_url) throw new Error('Missing api_url.');

      window.localStorage.setItem('settings', JSON.stringify(data));
      initMatomo(data.matomo_tracking_url, data.matomo_site_id);
      return new Vue({
        i18n,
        render: (h) => h(App),
        router,
        store,
      }).$mount('#app');
    })
    .catch((err) => new Vue({
      router,
      store,
      i18n,
      render: (h) => h(MissingSettingsError, { props: { err, errMsg: 'Could not download settings.json' } }),
    }).$mount('#app'));
} else {
  window.localStorage.setItem('settings', JSON.stringify(dev_settings));
  initMatomo(dev_settings.matomo_tracking_url, dev_settings.matomo_site_id);
  new Vue({
    i18n,
    render: (h) => h(App),
    router,
    store,
  }).$mount('#app');
}

/**
 * Initialisation of Matomo analytics service using vue-matomo library
 *
 * @param {string} trackingUrl Matomo tracking url
 * @param {string} siteId Matomo site ID
 */
function initMatomo(trackingUrl, siteId) {
  if (trackingUrl && siteId) {
    Vue.use(VueMatomo, {
      host: trackingUrl,
      siteId,
      // Enables automatically registering pageviews on the router
      router,
    });
  } else {
    console.warn('[MATOMO] Matomo is not enabled. Missing "matomo_tracking_url" and/or "matomo_site_id" config in settings.json');
  }
}
