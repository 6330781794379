<template>
  <b-container fluid class="dashboard" v-show="!error">
    <b-card id="dashboard" no-body class="statistics">
      <b-tabs>
        <!-- Recruitments tab -->
        <b-tab
          class="tab"
          :title="$t('dashboard.recruitment_list_tab_title')"
          active
        >
          <loading-inline
            v-if="!data_ready"
            id="dashboard-recruitments-loading"
          ></loading-inline>
          <recruitment-list-wrapper
            v-else
            :assessments="assessments"
          ></recruitment-list-wrapper>
        </b-tab>

        <!-- Statistics tab -->
        <b-tab
          class="tab"
          :title="`${
            selectedClient && selectedClient.name ? selectedClient.name : ''
          } ${$t('dashboard.statistics_tab_title')}`"
        >
          <loading-inline v-if="!statisticsReady"></loading-inline>
          <statistics
            v-else-if="selectedClient.statistics"
            :client="selectedClient"
            :showInviteOnlyStats="showInviteOnlyStats"
          >
          </statistics>
          <b-alert v-else show variant="primary" class="no-data mx-3">
            {{ $t("dashboard.statistics_tab.no_data") }}
          </b-alert>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RecruitmentListWrapper from "../components/RecruitementListWrapper.vue";
import Statistics from "../components/Statistics.vue";
import LoadingInline from "../components/LoadingInline.vue";

import api from "../common/api";

export default {
  components: {
    Statistics,
    RecruitmentListWrapper,
    LoadingInline,
  },
  props: {
    statisticsReady: {
      required: true,
    },
  },
  data() {
    return {
      data_ready: false,
      showInviteOnlyStats: false,
    };
  },
  computed: {
    ...mapState("dashboard", ["error", "client", "clientTree", "assessments"]),
    ...mapState("select", ["selectedClient", "selected"]),
  },
  methods: {
    ...mapActions("dashboard", ["getClient", "getStatistics"]),
    ...mapActions("select", ["setSelectedClient"]),
    checkInviteOnly() {
      if (this.assessments)
        this.showInviteOnlyStats = this.assessments.some((element) =>
          element.attributes.includes("INVITE_ONLY")
        );
      this.data_ready = true;
    },
  },

  /**
   * Vue lifecycle mounted
   */
  async mounted() {
    this.$store.commit("dashboard/clearAssessments");

    // Get client if we don't have it
    if (this.clientTree === null) {
      await this.getClient();
    }

    // Set selected client
    this.setSelectedClient(Number(this.$route.params.clientId));

    this.$store.commit("dashboard/setStatsReady", false);
    // Get statistics
    this.getStatistics({ clientId: this.$route.params.clientId });

    const result = await api.getAssessmentStatistics(
      this.$route.params.clientId
    );

    const filteredAssessments = result.data.assessments.map((assessment) => ({
      games: assessment.games,
      id: assessment.id,
      name: assessment.name,
      attributes: assessment.attributes,
      link: assessment.link,
      start_time: assessment.start_time,
      end_time: assessment.end_time,
      prefixed_id: `A-${assessment.id}`,
      video_members: assessment.has_video,
      finished_gameplays:
        assessment.quit_after_onboarding +
        assessment.completed +
        assessment.incomplete,
      ...assessment,
    }));
    this.$store.commit("dashboard/setAssessments", filteredAssessments);
    // Check if invite only category should be shown on statistics charts
    this.checkInviteOnly();
  },
};
</script>

<style lang="css">
.dashboard h1 {
  margin: 16px 0px 16px 0px;
}
.projects {
  margin-top: 30px;
  font-weight: bold;
}
.no-data {
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .statistics {
    width: 100% !important;
    margin: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .dashboard {
    padding: 0 !important;
  }
}
</style>
